import messagesEnglish from '../../translations/en';
import messagesSpanish from '../../translations/es';
import messagesItalian from '../../translations/it';
import messagesGerman from '../../translations/de';
import messagesFrench from '../../translations/fr';
import messagesPortuguese from '../../translations/pt';

export const locale = (navigator.languages && navigator.languages[0])
  || navigator.language
  || navigator.userLanguage
  || 'en-US';

// console.log('Locale:');
// console.log(locale);

export const language = locale.split(/[-_]/)[0];

export const messages = {
  en: messagesEnglish,
  es: messagesSpanish,
  it: messagesItalian,
  de: messagesGerman,
  fr: messagesFrench,
  pt: messagesPortuguese,
};

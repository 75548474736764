export function createAsyncAction(base) {
  const obj = {};
  ['REQUEST', 'FETCH', 'SUCCESS', 'FAILURE', 'CANCEL']
    .forEach((type) => { obj[type] = `${base}:${type}`; });
  return obj;
}

export function createAsyncActionArr(base) {
  return ['REQUEST', 'FETCH', 'SUCCESS', 'FAILURE', 'CANCEL']
    .map((type) => `${base}:${type}`);
}

import { config } from '../settings';
import { Transducer } from './transducer';

function redirect(url, replace = true, authPath = 'login') {
  const location = `${config.authEndPoint}/${authPath}?path=${encodeURI(url || window.location.href)}`;
  if (Transducer.isNative()) {
    // Force it in a new browser for SSO
    window.open(location, '_blank');
  } else if (replace) {
    window.location.replace(location);
  } else {
    window.location.href = location;
  }
}

// FIXME Test this - for the most part, it should always redirect to registration unless specifically requesting a login
export function redirectAuth(url, replace = true) {
  redirect(url, replace, 'register');
}

export function login(url, replace = true) {
  redirect(url, replace, 'login');
}

export function register(url, replace = true) {
  redirect(url, replace, 'register');
}

export function redirectSignOut(replace = false) {
  const location = `${config.authEndPoint}/login?action=sign_out`;
  if (Transducer.isNative()) {
    // Force it in a new browser for SSO
    window.open(location, '_blank');
  } else if (replace) {
    window.location.replace(location);
  } else {
    window.location.href = location;
  }
}
